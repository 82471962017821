.contact {
  $root: &;

  margin-top: map-get($spacings, 'xl');
  padding-top: map-get($spacings, 'xl');

  &--hidden {
    display: none;
  }

  &__form {
    max-width: 640px;
  }
}
