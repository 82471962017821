* {
  font-family: 'Poppins', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.body {
  background-color: map-get($colors, 'vulcan');
  color: map-get($colors, 'white');

  &--navigation-open {
    overflow: hidden;

    .main,
    .footer {
      display: none;
    }
  }
}

.main {
  @extend %wrapper;

  align-items: center;
  display: flex;
  flex-direction: column;
  padding: map-get($spacings, 'xl');
}

.section {
  @extend %wrapper;

  display: flex;
  flex-direction: column;
}
