.services {
  $root: &;

  margin-top: map-get($spacings, '3xl');
  padding-top: map-get($spacings, 'xl');

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 640px;

    @include respond-to('lg') {
      justify-content: unset;
      margin-left: unset;
      margin-right: unset;
      max-width: unset;
    }
  }

  #{$root}__service {
    height: 125px;
    width: 125px;

    @include respond-to('lg') {
      height: 150px;
      width: 150px;
    }
  }
}
