.hero {
  $root: &;

  &__heading {
    font-size: map-get($fonts, 'lg');

    @include respond-to('lg') {
      font-size: map-get($fonts, 'xl');
    }
  }

  &__img {
    height: auto;
    max-width: 100%;
  }

  &__span {
    color: map-get($colors, 'electric-violet');
    display: inline-block;

    &::before {
      content: '<';
    }

    &::after {
      content: '/>';
    }
  }
}
