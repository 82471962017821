@use 'sass:math';

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{inspect(map-get($breakpoints, $breakpoint))}) {
      @content;
    }
  } @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
      + 'Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}

@mixin create-spacings() {
  @each $name, $size in $spacings {
    .pd-#{$name} {
      padding: $size;
    }

    .pd-t-#{$name} {
      padding-top: $size;
    }

    .pd-b-#{$name} {
      padding-bottom: $size;
    }

    .pd-l-#{$name} {
      padding-left: $size;
    }

    .pd-r-#{$name} {
      padding-right: $size;
    }

    .mg-#{$name} {
      margin: $size;
    }

    .mg-t-#{$name} {
      margin-top: $size;
    }

    .mg-b-#{$name} {
      margin-bottom: $size;
    }

    .mg-l-#{$name} {
      margin-left: $size;
    }

    .mg-r-#{$name} {
      margin-right: $size;
    }
  }
}

@mixin create-grid-columns($name, $i, $columns) {
  $gap: map-get($layout, 'gap');
  $column-width: calc($i / $columns);
  $column-width-ratio: calc($columns / $i);
  $gap-ratio: calc($gap / $column-width-ratio);
  $column-percentage-width: percentage($column-width);
  $gap-width-per-column: $gap-ratio * ($column-width-ratio - 1);
  $width-without-gap: calc($column-percentage-width - $gap-width-per-column);

  .col-#{$name}-#{$i} {
    flex: 0 0 $column-percentage-width;
    width: $column-percentage-width;
  }

  .row.gap > .col-#{$name}-#{$i} {
    flex: 0 0 $width-without-gap;
    width: $width-without-gap;
  }
}

@mixin create-grid-columns-with-offset($name, $j, $columns) {
  $gap: map-get($layout, 'gap');
  $column-width: calc($j / $columns);
  $column-percentage-width: percentage($column-width);
  $column-gap-width-ratio: calc($gap / $columns * $j);

  .offset-#{$name}-#{$j} {
    margin-left: $column-percentage-width;
  }

  .row.gap > .offset-#{$name}-#{$j} {
    margin-left: calc($column-percentage-width + $column-gap-width-ratio);
  }
}

@mixin create-grid-helpers($name, $size) {
  .grid--#{$name} {
    max-width: $size;
  }

  .row,
  .col {
    &.ai-#{$name}-c {
      @media (min-width: $size) {
        align-items: center;
      }
    }
  
    &.ai-#{$name}-fs {
      @media (min-width: $size) {
        align-items: flex-start;
      }
    }
  
    &.ai-#{$name}-fe {
      @media (min-width: $size) {
        align-items: flex-end;
      }
    }
  
    &.jc-#{$name}-c {
      @media (min-width: $size) {
        justify-content: center;
      }
    }
  
    &.jc-#{$name}-fs {
      @media (min-width: $size) {
        justify-content: flex-start;
      }
    }
  
    &.jc-#{$name}-fe {
      @media (min-width: $size) {
        justify-content: flex-end;
      }
    }

    &.fd-#{$name}-c {
      @media (min-width: $size) {
        flex-direction: column;
      }
    }

    &.fd-#{$name}-cr {
      @media (min-width: $size) {
        flex-direction: column-reverse;
      }
    }

    &.fd-#{$name}-r {
      @media (min-width: $size) {
        flex-direction: row;
      }
    }

    &.fd-#{$name}-rr {
      @media (min-width: $size) {
        flex-direction: row-reverse;
      }
    }
  }

  .col-#{$name}--hidden {
    @media (min-width: $size) {
      display: none;
    }
  }
}

@mixin create-grid($columns: 12) {
  @each $name, $size in $breakpoints {
    @include create-grid-helpers($name, $size);

    @media (min-width: $size) {
      @for $i from 1 through $columns {
        @include create-grid-columns($name, $i, $columns);
      }

      @for $j from 1 through ($columns - 1) {
        @include create-grid-columns-with-offset($name, $j, $columns);
      }
    }
  }
}
