.navbar {
  @extend %wrapper;

  $root: &;

  align-items: center;
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: map-get($spacings, 'xl');

  &__heading {
    font-size: map-get($fonts, 'md');
  }

  &__hamburger {
    @include respond-to('lg') {
      display: none;
    }
  }

  &__menu {
    display: none;

    @include respond-to('lg') {
      display: flex;
    }

    &--open {
      background-color: map-get($colors, 'vulcan');
      display: flex;
      height: calc(100vh - 80px);
      left: 0;
      padding: map-get($spacings, 'xl');
      position: fixed;
      top: 80px;
      width: 100%;
      z-index: map-get($z-indexes, 'navigation');
    }

    &--open #{$root}__list {
      align-items: center;
      color: map-get($colors, 'white');
      display: flex;
      font-size: map-get($fonts, 'md');
      width: 100%;
    }
  }

  &__list {
    background-color: transparent;
    display: none;
    flex-direction: column;
    gap: 1rem;

    @include respond-to('lg') {
      display: flex;
      flex-direction: row;
    }
  
    &--languages {
      display: flex;
      flex-direction: row;
      font-size: map-get($fonts, 'xs');
      gap: .5rem;
      margin-left: auto;
      margin-right: map-get($spacings, 'md');

      &::after {
        @include respond-to('lg') {
          content: '|';
          padding-left: map-get($spacings, 'xs');
        }
      }
    }
  }
}
