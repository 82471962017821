$colors: (
  'black': #000000,
  'blue': #0028FF,
  'bondi-blue': #008DAC,
  'dusty-gray': #9B9B9B,
  'electric-violet': #B527FD,
  'electric-violet-2': #9110D4,
  'electric-violet-3': #6900EE,
  'eucalyptus': #28A745,
  'martinique': #31364D,
  'mirage': #1A1D29,
  'red': #FF0000,
  'red-orange': #FF3333,
  'silver': #C1C1C1,
  'supernova': #FFC800,
  'tundora': #464646,
  'valhalla': #321B60,
  'vulcan': #12121F,
  'white': #FFFFFF
);

$spacings: (
  '2xs': .25rem,
  'xs': .5rem,
  'sm': .75rem,
  'md': 1rem,
  'lg': 1.25rem,
  'xl': 1.5rem,
  '2xl': 2rem,
  '3xl': 4rem
);

$fonts: (
  '2xs': .75rem,
  'xs': .875rem,
  'sm': 1rem,
  'md': 1.5rem,
  'lg': 2rem,
  'xl': 2.25rem,
  '2xl': 2.5rem,
  '3xl': 3rem
);

$breakpoints: (
  '2xs': 20rem,
  'xs': 25.875rem,
  'sm': 40rem,
  'md': 48rem,
  'lg': 64rem,
  'xl': 75rem,
  '2xl': 90rem,
  '3xl': 120rem,
) !default;

$layout: (
  'gap': 1rem
);

$z-indexes: (
  'hamburger': 2,
  'navigation': 2
);
