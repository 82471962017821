.footer {
  $root: &;

  @extend %wrapper;

  margin-top: map-get($spacings, 'xl');
  padding: map-get($spacings, 'xl');

  &__list {
    text-align: center;

    @include respond-to('lg') {
      text-align: unset;
    }
  }
}
