%visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

%wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 100%;
}
