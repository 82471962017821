.form {
  $root: &;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;

  &--invalid {
    #{$root}-submit {
      background-color: map-get($colors, 'dusty-gray');
      cursor: not-allowed;

      &:focus,
      &:hover {
        background-color: map-get($colors, 'dusty-gray');
      }
    }
  }

  &-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-input {
    background-color: transparent;
    border: 1px solid map-get($colors, 'silver');
    border-radius: .25rem;
    color: map-get($colors, 'white');
    height: 48px;
    outline: none;
    padding: map-get($spacings, 'xs');
    width: 100%;

    &:focus,
    &:hover {
      background-color: map-get($colors, 'martinique');
    }
  }

  &-label {
    font-size: map-get($fonts, 'xs');
  }

  &-sup {
    font-size: map-get($fonts, '2xs');
  }

  &-textarea {
    background-color: transparent;
    border: 1px solid map-get($colors, 'silver');
    border-radius: .25rem;
    color: map-get($colors, 'white');
    min-height: 128px;
    outline: none;
    padding: map-get($spacings, 'xs');
    width: 100%;

    &:focus,
    &:hover {
      background-color: map-get($colors, 'martinique');
    }
  }

  &-submit {
    background-color: map-get($colors, 'electric-violet-2');
    border: none;
    border-radius: .25rem;
    color: map-get($colors, 'white');
    cursor: pointer;
    font-size: map-get($fonts, 'sm');
    height: 45px;
    margin-left: auto;
    outline: none;
    width: 145px;

    &:focus,
    &:hover {
      background-color: map-get($colors, 'electric-violet-3');
    }
  }
}