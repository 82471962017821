.timeline {
  $root: &;

  margin-top: map-get($spacings, 'xl');
  padding-top: map-get($spacings, 'xl');

  &__list {
    background-color: map-get($colors, 'white');
    display: flex;
    height: 120px;
    margin-left: map-get($spacings, 'xs');
    margin-top: map-get($spacings, '2xl');
    position: relative;
    width: 1px;

    @include respond-to('md') {
      height: 1px;
      margin-left: calc(-1 * ((100vw - 75%) / 2));
      margin-top: map-get($spacings, 'xl');
      width: 100%;
    }

    @include respond-to('2xl') {
      margin-left: calc(-1 * ((100vw - 100%) / 2));
    }

    @include respond-to('3xl') {
      width: 125%;
    }
  }

  &__item {
    align-items: center;
    display: flex;
    position: absolute;
    text-align: center;

    @include respond-to('md') {
      align-items: unset;
      display: unset;
    }

    &::before {
      background-color: map-get($colors, 'white');
      border-radius: 50%;
      color: map-get($colors, 'black');
      content: '';
      display: inline-block;
      height: 18px;
      margin-left: auto;
      margin-right: map-get($spacings, 'md');
      width: 18px;

      @include respond-to('md') {
        display: block;
        margin-right: auto;
      }
    }

    &:nth-of-type(1) {
      left: -9px;
      top: -9px;

      @include respond-to('md') {
        left: calc(50% - 66px);
        top: -9px;
        width: 132px;
      }

      &::before {
        background-color: map-get($colors, 'bondi-blue');
      }
    }

    &:nth-of-type(2) {
      left: -9px;
      top: calc(50% - 9px);

      @include respond-to('md') {
        left: calc(75% - 66px);
        top: -9px;
        width: 132px;
      }

      &::before {
        background-color: map-get($colors, 'blue');
      }
    }

    &:nth-of-type(3) {
      left: -9px;
      top: calc(100% - 9px);

      @include respond-to('md') {
        left: calc(100% - 66px);
        top: -9px;
        width: 132px;
      }

      &::before {
        background-color: map-get($colors, 'electric-violet-2');
      }
    }
  }
}