.grid {
  @extend %wrapper;

  &--fluid {
    max-width: unset;
  }
}

.row,
.col {
  display: flex;
  width: 100%;

  &.gap {
    gap: 1rem;
  }
}

.row {
  flex-wrap: wrap;
}

@include create-grid();