.hamburger {
  background-color: transparent;
  border: 0;
  border-radius: .125rem;
  border-top: 3px solid map-get($colors, 'white');
  cursor: pointer;
  height: 25px;
  outline: none;
  position: relative;
  transition: .25s transform linear;
  width: 25px;

  &:focus,
  &:hover {
    border-color: map-get($colors, 'silver');

    &::after,
    &::before {
      border-color: map-get($colors, 'silver');
    }
  }

  &::after,
  &::before {
    border-radius: .125rem;
    border-top: 3px solid map-get($colors, 'white');
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    transform: translateY(6px);
    width: 100%;
  }

  &::after {
    transform: translateY(15px);
    transition: .25s transform linear;
  }

  &--active {
    border: none;
    outline: none;
    transform: rotate(45deg) translateY(3px);
    z-index: map-get($z-indexes, 'hamburger');

    &::after {
      transform: rotate(-90deg) translateX(-6px);
    }
  }

  &__label {
    @extend %visuallyhidden;
  }
}