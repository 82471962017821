@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

@include create-spacings();

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: unset;
  margin-top: unset;
}

h1,
.h1 {
  font-size: map-get($fonts, 'lg');

  @include respond-to('lg') {
    font-size: map-get($fonts, 'xl');
  }
}

h2,
.h2 {
  font-size: map-get($fonts, 'md');

  @include respond-to('lg') {
    font-size: map-get($fonts, 'lg');
  }
}

h3,
.h3,
h4,
.h4
h5,
.h5 {
  font-size: map-get($fonts, 'sm');
  
  @include respond-to('lg') {
    font-size: map-get($fonts, 'md');
  }
}

h6,
.h6 {
  font-size: map-get($fonts, 'sm');
}

p,
.text {
  font-size: map-get($fonts, 'sm');

  &--md {
    font-size: map-get($fonts, 'md');
  }

  &--xs {
    font-size: map-get($fonts, 'xs');
  }

  &--success {
    color: map-get($colors, 'eucalyptus');
  }

  &--warning {
    color: map-get($colors, 'supernova');
  }

  &--error {
    color: map-get($colors, 'red-orange');
  }

  &--muted {
    color: map-get($colors, 'silver');
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.text {
  &--centered {
    text-align: center;
  }

  &--hidden {
    display: none;
  }

  &--max-width {
    max-width: 23.875rem;

    @include respond-to('lg') {
      max-width: 32.8125rem;
    }
  }
}

a {
  color: map-get($colors, 'white');
  outline: none;
  text-decoration: none;

  &:focus {
    color: map-get($colors, 'silver');
  }

  &:hover {
    color: map-get($colors, 'dusty-gray');
  }
}

ul,
ol {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

.list {
  &--circle {
    list-style: circle;
    padding-left: revert;
  }
}